export const videoFiles = [
  { url: "1.Шахмат+тақтасы+және+тастары.mp4", topic: "Шахмат тақтасы және тастары" },
  { url: "2.Көлденең+және+тік+жолдар.Ладья.mp4", topic: "Көлденең және тік жолдар. Ладья" },
  { url: "3.Диагональ.+Піл.mp4", topic: "Диагональ. Піл" },
  { url: "4.Ферзь.Ферзьдің+жүрісі.mp4", topic: "Ферзь. Ферзьдің жүрісі" },
  { url: "5.Ат.Аттың+жүрісі.mp4", topic: "Ат. Аттың жүрісі" },
  { url: "6.Сарбаз.Сыйқырлы+сарбаз+жүрісі.mp4", topic: "Сарбаз. Сыйқырлы сарбаз жүрісі" },
  { url: "7.Патша.mp4", topic: "Патша" },
  { url: "8.Шахмат+тастарының+құңдылығы.mp4", topic: "Шахмат тастарының құңдылығы" },
  { url: "9.Патшаның+ерекше+жүрісі.Ракировка.mp4", topic: "Патшаның ерекше жүрісі. Ракировка" },
  { url: "10.Сарбаздың+ерекше+жүрісі.Өткелді+алу.mp4", topic: "Сарбаздың ерекше жүрісі. Өткелді алу" },
  { url: "11.Шахмат+ойнау+әдебі.mp4", topic: "Шахмат ойнау әдебі" },
  { url: "12.Шабуылдау.mp4", topic: "Шабуылдау" },
  { url: "13.Қорғаныс+түрлері.mp4", topic: "Қорғаныс түрлері" },
  { url: "14.Патшаның+айрықша+рөлі.+Шах.mp4", topic: "Патшаның айрықша рөлі. Шах" },
  { url: "15.Шахтан+қорғану.mp4", topic: "Шахтан қорғану" },
  { url: "16.Мат.mp4", topic: "Мат" },
  { url: "17.Бір+жүрістен+мат+қою.mp4", topic: "Бір жүрістен мат қою" },
  { url: "18.Сызықтық+мат.mp4", topic: "Сызықтық мат" },
  { url: "19.+Ладья+және+патшамен+мат+қою.mp4", topic: "Ладья және патшамен мат қою" },
  { url: "20.+Ферзь+және+патшамен+мат+қою.mp4", topic: "Ферзь және патшамен мат қою" },
  { url: "21.+Тең+ойын.Тең+ойын+түрлері.Пат.mp4", topic: "Тең ойын. Тең ойын түрлері. Пат" },
  { url: "22.+Бала+маты.mp4", topic: "Бала маты" },
  { url: "23.+Қос+соққы.mp4", topic: "Қос соққы" },
  { url: "24.+Өтпелі+соққы.mp4", topic: "Өтпелі соққы" },
  { url: "25.+Байлам.mp4", topic: "Байлам" },
  { url: "26.+Ашық+шах.mp4", topic: "Ашық шах" },
  { url: "27.+Ашық+шабуыл.mp4", topic: "Ашық шабуыл" },
  { url: "28.+Патша+және+сарбаз+патшаға+қарсы+Шаршы+ережесі.mp4", topic: "Патша және сарбаз патшаға қарсы. Шаршы ережесі" },
  { url: "29.+Оппозиция.mp4", topic: "Оппозиция" },
  { url: "30.+Сарбазды+ферзьге+қалай+өткізуге+болады.mp4", topic: "Сарбазды ферзьге қалай өткізуге болады" },
  { url: "31.+Шахмат+комбинациясы.Құрбандық.mp4", topic: "Шахмат комбинациясы. Құрбандық" },
  { url: "32.Шахмат+комбинациясы.Арбау.mp4", topic: "Шахмат комбинациясы. Арбау" },
  { url: "33.+Шахмат+комбинациясы.Тосқауылдау.mp4", topic: "Шахмат комбинациясы. Тосқауылдау" },
  { url: "34.Шахмат+комбинациясы.Алаңдату.mp4", topic: "Шахмат комбинациясы. Алаңдату" },
  { url: "35.Шахмат+комбинациясы.Шаршыны+немесе+жолды+босату..mp4", topic: "Шахмат комбинациясы. Шаршыны немесе жолды босату" },
  { url: "36.+Ладьяны+жеңіп+алу.mp4", topic: "Ладьяны жеңіп алу" },
  { url: "37.+Піл+мен+Атты+жеңіп+алу.mp4", topic: "Піл мен Атты жеңіп алу" },
  { url: "38.+Ферзьді+жеңіп+алу.mp4", topic: "Ферзьді жеңіп алу" },
  { url: "39.Екі+жүрісте+қос+шах+арқылы+мат+қою.mp4", topic: "Екі жүрісте қос шах арқылы мат қою" },
  { url: "40.Екі+жүрісте+Ферзьді+құрбандық+жасау+арқылы+мат+қою.mp4", topic: "Екі жүрісте Ферзьді құрбандық жасау арқылы мат қою" },
  { url: "41.Екі+жүрісте+Ладьяны+құрбандық+жасау+арқылы+мат+қою.mp4", topic: "Екі жүрісте Ладьяны құрбандық жасау арқылы мат қою" },
  { url: "42.+Екі+жүрісте+Ферзьбен+мат+қою.mp4", topic: "Екі жүрісте Ферзьбен мат қою" },
  { url: "43.+Екі+жүрісте+Ладьямен+мат+қою.mp4", topic: "Екі жүрісте Ладьямен мат қою" },
  { url: "44.+Екі+жүрісте+атпен+мат+қою.mp4", topic: "Екі жүрісте атпен мат қою" },
  { url: "45.+Орыс+партиясы.mp4", topic: "Орыс партиясы" },
  { url: "46.+Венгер+қорғанысы.mp4", topic: "Венгер қорғанысы" },
  { url: "47.+Ағылшын+гамбиты.mp4", topic: "Ағылшын гамбиты" },
  { url: "48.+Филедор+қорғанысы.mp4", topic: "Филедор қорғанысы" },
  { url: "49.Легаль+маты.mp4", topic: "Легаль маты" },
  { url: "50.+Испандық+партиясы.mp4", topic: "Испандық партиясы" }
];
