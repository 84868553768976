export const difficultySettings = [
    {
        name: "Кочевник Айбек",
        depth: 1,
        icon: "/difficulties/400.jpeg",
        story: "Айбек только начинает осваивать шахматы, и его тактика так же непредсказуема, как пески казахской степи. Он мечтает стать гроссмейстером, но пока полагается больше на интуицию, чем на глубокую стратегию.",
        elo: 400
    },
    {
        name: "Лучница Жанар",
        depth: 3,
        icon: "/difficulties/800.jpeg",
        story: "Жанар — гордая лучница из Алматы, которая занялась шахматами, чтобы улучшить свою концентрацию и точность. Ее ходы прямолинейны, но рассчитаны, и она всегда стремится к центру доски.",
        elo: 800
    },
    {
        name: "Тренер орлов Ерлан",
        depth: 5,
        icon: "/difficulties/1200.jpeg",
        story: "Ерлан вырос в горах, обучая орлов и постигая терпение. В шахматах он придерживается медленного и осторожного подхода, терпеливо ожидая ошибок соперника.",
        elo: 1200
    },
    {
        name: "Поэт Алия",
        depth: 7,
        icon: "/difficulties/1500.jpeg",
        story: "Алия — поэт из Астаны, которая воспринимает шахматы как танец между креативностью и стратегией. Ее ходы часто неожиданны и вдохновенны, что сбивает противников с толку.",
        elo: 1500
    },
    {
        name: "Стратег Султан",
        depth: 9,
        icon: "/difficulties/1800.jpeg",
        story: "Султан — тактик и историк, увлеченный стратегиями, применяемыми как в войне, так и в шахматах. Он всегда ищет лучший ход и представляет собой серьезный вызов для продвинутых игроков.",
        elo: 1800
    },
    {
        name: "Мудрая Гаухар",
        depth: 11,
        icon: "/difficulties/2000.jpeg",
        story: "Гаухар пережила множество шахматных битв, и ее мудрость не знает границ. Она отлично знает шахматную историю и любит учить молодых и смелых игроков.",
        elo: 2000
    },
    {
        name: "Воин Тимур",
        depth: 13,
        icon: "/difficulties/2200.jpeg",
        story: "Тимур — воин с острым умом и еще более острыми тактическими приемами. Он воспринимает каждую партию как битву и делает быстрые, просчитанные ходы, чтобы перехитрить соперников.",
        elo: 2200
    },
    {
        name: "Гроссмейстер Аисулу",
        depth: 15,
        icon: "/difficulties/2500.jpeg",
        story: "Аисулу представляла Казахстан на международных шахматных турнирах, принося честь своей родине. Она спокойна, расчетлива и почти непобедима.",
        elo: 2500
    }
];
